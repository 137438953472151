import { WorkspaceSelector } from '@/components/workspaceSelector/WorkspaceSelector.tsx'
import { ProfileDropdown } from './ProfileDropdown'
import { HelpCenter } from './helpCenter'
import FileQuestion from '@/assets/icons/file-question.svg?react'
import { Button } from '@/components/ui/button.tsx'
import { Feedback } from './feedback'

export const TopNav = () => {
    return (
        <div className="h-16 flex items-center px-5 border-b shadow-sm finish-step">
            <div className={'flex w-full justify-end items-center gap-3'}>
                <HelpCenter />
                <Feedback />
                <WorkspaceSelector />
                <a href="https://airia.com/explore" target="_blank" rel="noreferrer">
                    <Button variant="ghost" size="icon">
                        <FileQuestion className="w-6 h-6" />
                    </Button>
                </a>
                <ProfileDropdown />
            </div>
        </div>
    )
}
