import { useTheme } from '@/lib/ThemeProvider'
import { getAuthUrl } from '@/lib/utils'
import { Link } from '@tanstack/react-router'
import { Check, Moon, Sun, UserCircle } from 'lucide-react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from '@/lib/i18n'
import { Button } from './ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from './ui/dropdown-menu'
import { Label } from './ui/label'
import { Switch } from './ui/switch'

export const ProfileDropdown = () => {
    const { setTheme, theme } = useTheme()
    const { t, i18n } = useTranslation()

    type Language = 'en' | 'es' | 'it' | 'de' | 'fr' | 'pt'

    const changeLanguage = (lng: Language) => {
        i18n.changeLanguage(lng)
    }

    const languages: { lng: Language; label: string; flag: string; disabled: boolean }[] = [
        { lng: 'en', label: t('english'), flag: 'US', disabled: false },
        { lng: 'es', label: t('spanish'), flag: 'ES', disabled: false },
        { lng: 'it', label: t('italian'), flag: 'IT', disabled: true },
        { lng: 'de', label: t('german'), flag: 'DE', disabled: true },
        { lng: 'fr', label: t('french'), flag: 'FR', disabled: true },
        { lng: 'pt', label: t('portuguese'), flag: 'PT', disabled: true },
    ]

    if (theme == 'dark') {
        setTheme('light')
    }
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                    <UserCircle />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem asChild>
                    <Link to="/administration/projects">{t('administration')}</Link>
                </DropdownMenuItem>
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger>
                        <span>{t('language')}</span>
                    </DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                        {languages.map(({ lng, label, flag, disabled }) => (
                            <DropdownMenuItem
                                key={lng}
                                onClick={() => changeLanguage(lng)}
                                disabled={disabled}
                            >
                                <ReactCountryFlag
                                    countryCode={flag}
                                    svg
                                    style={{
                                        width: '1.25em',
                                        height: '1.25em',
                                        marginRight: '0.5em',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                {label}
                                {i18n.language === lng && <Check className="ml-2 h-4 w-4" />}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuSubContent>
                </DropdownMenuSub>
                <DropdownMenuItem asChild>
                    <a
                        href={
                            import.meta.env.DEV
                                ? 'https://localhost:5001/auth/logout'
                                : `${getAuthUrl()}/MicrosoftIdentity/Account/SignOut`
                        }
                    >
                        {t('logout')}
                    </a>
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="flex gap-2 items-center"
                    onClick={(e) => e.preventDefault()}
                >
                    <Label className="sr-only fixed" htmlFor="toggle-theme">
                        {t('toggle_theme')}
                    </Label>
                    <Switch
                        id="toggle-theme"
                        disabled
                        onCheckedChange={(event) => {
                            if (event) {
                                setTheme('dark')
                            } else {
                                setTheme('light')
                            }
                        }}
                        defaultChecked={false}
                    />
                    <div className="flex">
                        <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                        <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                    </div>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
