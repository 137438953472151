import { getActiveTenant, getOrganizations, setTenant } from '@/api/workspaces.ts'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx'
import { Button } from '@/components/ui/button.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { Check, ChevronDown, Loader2, RocketIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu'

interface WorkspaceSelectorProps {}

export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = () => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [tenantSwitchStatus, setTenantSwitchStatus] = useState({
        isSwitching: false,
        switched: false,
    })
    const [tenantSelectorVisible, setTenantSelectorVisible] = React.useState(false)

    const getOrganizationsQuery = useQuery({
        queryKey: [QueryKeys.ORGANIZATIONS],
        queryFn: () => getOrganizations(),
    })

    const getActiveTenantQuery = useQuery({
        queryKey: [QueryKeys.ACTIVE_TENANT],
        queryFn: () => getActiveTenant(),
    })

    useEffect(() => {
        if (getOrganizationsQuery.data) {
            const multipleTenants =
                getOrganizationsQuery.data.length > 1 ||
                getOrganizationsQuery.data.some((organization) => organization.tenants.length > 1)

            setTenantSelectorVisible(multipleTenants)
        }
    }, [getOrganizationsQuery.data])

    const setUserTenant = async (organizationId: string, tenantId: string) => {
        // set the tenant for the user
        console.log('Setting the tenant for the user')
        const tenantSwitchResult = await setTenant(organizationId, tenantId)
        console.log('Tenant switch status code: ', tenantSwitchResult)
        if (tenantSwitchResult) {
            setTenantSwitchStatus({ isSwitching: true, switched: false })
            // Invalidates all queries
            await queryClient.invalidateQueries()
            await router.invalidate()
            setTenantSwitchStatus({ isSwitching: false, switched: true })
        } else {
            //Closes the modal. The user is most likely attempting to switch to the same tenant.
            toast({
                title: t('error_title'),
                description: t('workspaces.error_description'),
                variant: 'destructive',
            })
            setDropDownOpen(false)
        }
    }

    return (
        tenantSelectorVisible && (
            <DropdownMenu open={dropDownOpen} onOpenChange={setDropDownOpen}>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant={'ghost'}
                        className="gap-2 shadow-none pl-4 pr-4 rounded-full bg-primary hover:bg-primary/20"
                    >
                        <p className="text-sm text-white dark:text-black hover:text-white dark:hover:text-black">
                            {getActiveTenantQuery.data?.name}
                        </p>
                        <ChevronDown className="w-4 text-white dark:text-black hover:text-white dark:hover:text-black" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align={'end'} className={'w-[411px] bg-background p-0'}>
                    <div className="flex justify-left gap-4 pt-4 pl-4">
                        <h1 className="uppercase font-bold text-sm">
                            {t('workspaces.select_tenant')}
                        </h1>
                    </div>
                    <hr className="mt-2 mb-2" />
                    <div className="pl-4 pr-4">
                        {tenantSwitchStatus.isSwitching ? (
                            <div className="flex flex-col w-full">
                                <Alert>
                                    <RocketIcon className="h-4 w-4 mt-2" />
                                    <AlertTitle>
                                        {t('workspaces.switching_workspace_header')}
                                    </AlertTitle>
                                    <AlertDescription>
                                        {t('workspaces.switching_workspace_message')}
                                    </AlertDescription>
                                </Alert>
                                <Loader2 className="h-8 w-8 my-8 animate-spin self-center" />
                            </div>
                        ) : tenantSwitchStatus.switched ? (
                            <div className="flex flex-col w-full">
                                <Alert>
                                    <Check className="h-4 w-4 mt-2" />
                                    <AlertTitle>
                                        {t('workspaces.workspace_switched_header')}
                                    </AlertTitle>
                                    <AlertDescription>
                                        {t('workspaces.workspace_switched_message')}
                                    </AlertDescription>
                                </Alert>
                                {/*Navigates to the base path replacing navigation history.*/}
                                <Button
                                    className="flex justify-items-center basis-1/2 my-8"
                                    onClick={() =>
                                        window.location.replace(router.basepath.toString())
                                    }
                                >
                                    {t('workspaces.access_new_workspace')}
                                </Button>
                            </div>
                        ) : (
                            <div className="flex justify-left gap-2">
                                {getOrganizationsQuery.data
                                    ? getOrganizationsQuery.data.map((organization, _) => (
                                          <div
                                              key={organization.id}
                                              className="p-1 flex flex-1 flex-col gap-2 bg-background"
                                          >
                                              {organization.tenants.map((tenant, _) => (
                                                  <div key={tenant.id} className="flex grow shrink">
                                                      <Button
                                                          disabled={
                                                              getActiveTenantQuery.data?.id ===
                                                              tenant.id
                                                          }
                                                          className="grow shrink w-full mr-2 hover:border-primary break-all truncate dark:disabled:bg-primary dark:disabled:text-black disabled:bg-primary disabled:text-white justify-left"
                                                          variant={'outline'}
                                                          key={tenant.id}
                                                          onClick={() =>
                                                              setUserTenant(
                                                                  organization.externalId,
                                                                  tenant.id
                                                              )
                                                          }
                                                      >
                                                          {tenant.identifier}
                                                      </Button>
                                                  </div>
                                              ))}
                                          </div>
                                      ))
                                    : t('loading')}
                            </div>
                        )}
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    )
}
