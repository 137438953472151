import { DeleteTool, Tool, getAllToolsByProjectId } from '@/api/tools'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Toaster } from '@/components/ui/toaster'
import { QueryKeys } from '@/constants/QueryKeys'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { Check, MoreHorizontal, Pencil, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/tools/')({
    component: () => {
        const params = Route.useParams()
        return <AllTools key={`models-overview-${params.projectId}`} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function AllTools() {
    const { t } = useTranslation()
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const navigate = Route.useNavigate()
    const queryClient = useQueryClient()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })
    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)

    const GetAllTools = useQuery({
        queryKey: [QueryKeys.ALL_TOOLS, params.projectId, queryParams],
        queryFn: () =>
            getAllToolsByProjectId(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams, projectId: params.projectId }
                    : { pageSize: 25, pageNumber: 1, projectId: params.projectId }
            ),
    })

    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const deleteToolMutation = useMutation({
        mutationFn: (toolId: string) => DeleteTool(toolId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_TOOLS] })
        },
        onError: (error) => {
            console.error(error)
        },
    })

    const columns: ColumnDef<Tool>[] = [
        {
            accessorKey: 'name',
            header: 'Tool Information',
            cell: ({ row }) => {
                return (
                    <div className="flex items-center">
                        <div className="ml-4 space-y-1">
                            <p className="text-sm font-medium leading-none">{row.original.name}</p>
                            <p className="text-sm text-muted-foreground">
                                {row.original.description}
                            </p>
                        </div>
                    </div>
                )
            },
        },
        {
            accessorKey: 'status',
            header: 'Status',
            cell: () => {
                return (
                    <Badge className="bg-success text-success-foreground hover:bg-success hover:text-success-foreground cursor-default rounded-full font-gilroy-medium text-sm items-center">
                        <Check className="mr-1 h-4 w-4" />
                        Ready to use
                    </Badge>
                )
            },
        },
        {
            accessorKey: 'updatedAt',
            header: t('last_modified'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.updatedAt ?? '').toLocaleString()}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={() => {
                                    navigate({
                                        to: '/$projectId/tools/$toolId',
                                        params: {
                                            projectId: params.projectId,
                                            toolId: row.original.id,
                                        },
                                    })
                                }}
                                className="flex gap-1"
                            >
                                <Pencil className="h-4 w-4" /> {t('edit')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => deleteToolMutation.mutateAsync(row.original.id)}
                                className="flex gap-1 text-red-500"
                            >
                                <Trash className="h-4 w-4" />
                                {t('delete')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState])

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex justify-between items-center">
                <div className={'flex gap-2 items-center'}>
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                </div>
                <Link to={'/$projectId/tools/new'} params={{ projectId: params.projectId }}>
                    <Button>{t('tools.new_tool')}</Button>
                </Link>
            </div>
            <DefaultDataTable
                data={GetAllTools?.data?.items ?? []}
                columns={columns}
                onRowClick={(row: { original: { id: string } }) => {
                    navigate({
                        to: '/$projectId/tools/$toolId',
                        params: { projectId: params.projectId, toolId: row.original.id },
                    })
                }}
                rowsPerPage={queryParams.pageSize}
                pagination
                totalCount={GetAllTools.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Toaster />
        </div>
    )
}
