import apiRequest from '@/lib/apiClient'
export type WebSocketUrlRequest = {
    SocketIdentifier: string
}

export type WebSocketUrlResponse = {
    webSocketUrl: string
}

export const getWebSocketUrl = async (socketIdentifier: string): Promise<WebSocketUrlResponse> => {
    return apiRequest('/v1/StreamSocketConfig/GenerateUrl', {
        method: 'POST',
        body: { SocketIdentifier: socketIdentifier },
    })
}
